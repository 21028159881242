/* eslint-disable */
import algoliasearch from 'algoliasearch/lite';
import { graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { InstantSearch, Pagination, SearchBox, connectStateResults } from 'react-instantsearch-dom';
import styled from 'styled-components';
import { useLocale } from '../hooks/layout/useLocale';
import { desktopBreakpoint, desktopVW, mobileVW, zIndex } from '../styles/index';

import ProductThumbnail from '../components/shared/ProductThumbnail';

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
);

const Hits = connectStateResults(({ searchResults, searchState }) => {
  const [emptySearch, setemptySearch] = useState(true);
  useEffect(() => {
    if (Object.keys(searchState).length === 0 || (searchState && searchState.query === '')) {
      return setemptySearch(true);
    }
    setemptySearch(false);
  }, [Object.keys(searchState).length]);

  return (
    <HitsWrapper emptySearch={emptySearch}>
      <Results>
        {searchState.query && (
          <>
            {searchResults && searchResults.hits.length >= 1 ? (
              <>
                {searchResults.hits.map((hit, index) => (
                  <ThumbnailWrapper key={hit.slug + index}>
                    <ProductThumbnail
                      index={index}
                      sku={hit.shopifyData?.variants[0]?.sku}
                      pageAllProduct={false}
                      thumbnailImages={hit.thumbnailImages}
                      title={hit.titleToShow}
                      price={hit.shopifyData.variants[0].price}
                      compareAtPrice={hit.shopifyData.variants[0].compare_at_price}
                      invertedImages={false}
                      slug={hit.slug}
                      productTag={hit.productTag}
                      isMobileGrid="potato"
                      // isBackgroundTransparent
                    />
                  </ThumbnailWrapper>
                ))}
              </>
            ) : (
              <NoResult>We couldn't match anything with your search</NoResult>
            )}
          </>
        )}
      </Results>
      {searchState.query && (
        <Pagination showFirst={false} showPrevious={false} showNext={false} showLast={false} />
      )}
    </HitsWrapper>
  );
});

export interface searchProps {
  data: {
    contentfulPageSearch: {
      searchText: string;
    };
  };
}

const Search = ({ data: { contentfulPageSearch } }: searchProps) => {
  const { searchText } = contentfulPageSearch;

  const locale = useLocale();

  return (
    <>
      <WrapperOut>
        <Wrapper>
          <InstantSearch
            indexName={
              locale === 'en'
                ? process.env.GATSBY_ALGOLIA_INDEX_NAME
                : process.env.GATSBY_ALGOLIA_INDEX_NAME_NL
            }
            searchClient={searchClient}
          >
            <StyledInput>
              <SearchBox autoFocus translations={{ placeholder: searchText }} />
            </StyledInput>
            <Hits />
          </InstantSearch>
        </Wrapper>
      </WrapperOut>
    </>
  );
};

const HitsWrapper = styled.div`
  margin-top: 0;
  ${desktopBreakpoint} {
    ${({ emptySearch }) => (emptySearch ? null : `margin-top: ${desktopVW(-100)};`)}
  }
`;

const WrapperOut = styled.div`
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  ${desktopBreakpoint} {
    justify-content: center;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: ${zIndex.surface};

  .ais-Pagination-list {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
    font-family: 'messinaMono', 'Courier New', Courier, monospace;
    padding: ${mobileVW(25)};
    font-size: ${mobileVW(22)};
    z-index: ${zIndex.surface};

    .ais-Pagination-link--selected {
      border-bottom: 1px solid black;
    }
    & > li {
      list-style: none;
      padding: 0 ${mobileVW(10)};

      ${desktopBreakpoint} {
        padding: 0 ${desktopVW(10)};
      }
    }
    ${desktopBreakpoint} {
      width: 100vw;
      font-size: ${desktopVW(18)};
      padding: ${desktopVW(50)} ${desktopVW(20)};
    }
  }
  ${desktopBreakpoint} {
    padding: ${desktopVW(0)} ${desktopVW(170)} 0 ${desktopVW(170)};
  }
`;

const Results = styled.div`
  width: 100%;
  display: grid;
  padding: ${mobileVW(20)} ${mobileVW(20)} 0 ${mobileVW(20)};
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: ${mobileVW(20)};
  z-index: ${zIndex.surface};

  ${desktopBreakpoint} {
    grid-template-columns: ${desktopVW(310)} ${desktopVW(310)} ${desktopVW(310)} ${desktopVW(310)};
    grid-template-rows: ${desktopVW(455)};
    grid-gap: ${desktopVW(26)};
    padding: ${desktopVW(26)} ${desktopVW(60)} 0 ${desktopVW(60)};
  }
`;

const ThumbnailWrapper = styled.div`
  position: relative;
  width: 100%;
  height: ${mobileVW(310)};
  z-index: ${zIndex.surface};
  ${desktopBreakpoint} {
    height: ${desktopVW(455)};
  }
`;

const NoResult = styled.div`
  font-family: 'messinaMono', 'Courier New', Courier, monospace;
  letter-spacing: -0.05em;
  font-size: ${mobileVW(17)};
  position: absolute;
  top: ${mobileVW(150)};
  left: ${mobileVW(25)};
  width: ${mobileVW(270)};
  ${desktopBreakpoint} {
    font-size: ${desktopVW(25)};
    top: ${desktopVW(480)};
    left: ${desktopVW(63)};
  }
`;

const StyledInput = styled.div`
  border-radius: 0;
  top: 0;
  padding-bottom: ${mobileVW(16)};
  z-index: ${zIndex.surface};

  button {
    display: none;
  }

  form {
    margin: auto;
    border-radius: 0;
  }

  input {
    font-family: 'messinaMono', 'Courier New', Courier, monospace;
    background: transparent;
    width: 100vw;
    font-size: ${mobileVW(50)};
    border: none;
    text-align: left;
    border-radius: 0;
    padding: ${mobileVW(70)} ${mobileVW(20)} 0 ${mobileVW(20)};
    min-height: auto;

    button {
      display: none;
    }
    &:focus {
      outline: none;
    }
    &:placeholder {
      text-transform: uppercase;
    }
    ${desktopBreakpoint} {
      font-size: ${desktopVW(90)};
      padding: 0 ${desktopVW(55)};
      min-height: ${desktopVW(300)};
    }
  }

  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  ${desktopBreakpoint} {
    padding-bottom: ${desktopVW(0)};
  }
`;

export default Search;

export const SearchPageQuery = graphql`
  query SearchPage($id: String!) {
    contentfulPageSearch(id: { eq: $id }) {
      title
      searchText
    }
  }
`;
